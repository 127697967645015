@font-face {
  font-family: 'BM-Biotif';
  src: url('./fonts/bm-biotif/BM-Biotif-Bold.eot'), url('./fonts/bm-biotif/BM-Biotif-Bold.woff2') format('woff2'),
  url('./fonts/bm-biotif/BM-Biotif-Bold.woff') format('woff'),
  url('./fonts/bm-biotif/BM-Biotif-Bold.ttf') format('truetype'),
  url('./fonts/bm-biotif/BM-Biotif-Bold.svg#BM-Biotif-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'uknumberplate';
  src: url('./fonts/number-plate/uknumberplate-webfont.eot'),
  url('./fonts/number-plate/uknumberplate-webfont.woff2') format('woff2'),
  url('./fonts/number-plate/uknumberplate-webfont.woff') format('woff'),
  url('./fonts/number-plate/uknumberplate-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
* {
  margin: 0;
  padding: 0;
}
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  color: #515151;
  font-family: 'Roboto', sans-serif;
  background: #F4F1F4;
}
* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}
a strong {
  color: inherit;
}
a:hover,
a:focus {
  text-decoration: none;
}


.behind {
  position: relative;
  z-index: 1;
  display: inline-block;
  &:before {
    content: '';
    width: 100%;
    height: 3px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #f9644b;
    border-radius: 30px;
    z-index: -1;
  }
}

.overlay-wrapper {
  width: 328px!important;
  padding: 64px 24px 24px 24px!important;
  .dismiss-wrapper {
    top: 24px!important;
    right: 24px!important;
  }
}

.magazine-widget {
  color: #320B42;
  background: #fff;
  width: 320px;
  height: 500px;
  padding: 24px;
  .break {
    display: block;
  }
  h1 {
    padding-bottom: 16px;
  }
  .lead {
    margin-bottom: 32px;
  }
  .enter-reg {
    width: 272px;
    .input-wrapper {
      max-width: 100%;
    }
    margin-bottom: 24px;
  }
  .quote-me {
    width: 272px!important;
  }
  h1 {
   display: block;
  }
  h4{
    display: none;
  }
  .mobile-button {
    display: none;
  }
  &.mobile-view {
    width: 100%;
    height: 145px;
    padding: 18px;
    overflow: hidden;
    .desktop-button {
      display: none;
    }
    .mobile-button {
      display: block;
    }
    h4 {
      display: block;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 16px;
      max-width: 85%;
    }
    h1{
      display: none;
    }
    .lead {
      display: none;
    }
    .enter-reg {
      width: 156px;
      height: 48px!important;
      .input-wrapper {
        height: 48px!important;
      }
    }
    .input-button-wrapper {
      display: flex;
      justify-content: space-between;
    }
    .quote-me {
      width: 156px!important;
      height: 48px!important;
    }
  }

}
